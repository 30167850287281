import React, { ReactNode} from 'react';
import './styles.scss'

interface ContainerProps {
    children: ReactNode;
}

const Index: React.FC<ContainerProps> = ({children}) => {
    return <div className={'container-wrapper'}>{children}</div>;
};

export default Index;