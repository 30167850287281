import React, {ReactNode, CSSProperties} from 'react';
import Container from './Container';
import tw from 'tailwind-styled-components';

interface ContainerProps extends React.HTMLAttributes<HTMLHtmlElement> {
    children: ReactNode;
    secondary?: boolean;
}

const ResponsivePadding = tw.div`
  md:pt-15
  md:pb-15
  pt-10
  pb-10
`;

const Section: React.FC<ContainerProps> = ({children, secondary = false}) => {
    return <div className={`overflow-hidden min-h-section flex justify-center items-center ${secondary ? 'secondary-background' : ''}`}>
        <Container>
            <ResponsivePadding>{children}</ResponsivePadding>
        </Container>
    </div>;
};
export default Section;
