'use client'

import Section from "@/components/Layout/Section";
import React from "react";

export default function Error() {
    return (
        <>
            <Section>
                <div className="flex flex-col items-center justify-center">
                    <span className="text-5xl text-red-500 mdi mdi-emoticon-sad-outline mb-4" />
                    <h1 className="text-3xl font-bold mb-2">Erro ao carregar a página</h1>
                    <p className="text-gray-600 text-xl">Por favor, tente novamente mais tarde.</p>
                </div>
            </Section>
        </>
    )
}
